import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment/moment';

export default function GanttChart({ role }) {
    console.log(role)
    const [projects, setProjects] = useState([]);
    const [projectLabel, setProjectLabel] = useState([]);
    const [selectedTimeUnit, setSelectedTimeUnit] = useState('quarter'); // Default value

    const handleTimeUnitChange = (event) => {
        setSelectedTimeUnit(event.target.value);
    };

    const getDateFromPercentage = (startDate, endDate, percentage) => {
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);

        const duration = endMoment.diff(startMoment);
        const percentageMillis = (percentage / 100) * duration;

        const resultMoment = startMoment.clone().add(percentageMillis);

        return resultMoment.format('YYYY-MM-DD');
    };
    useEffect(() => {
        const fetchData = async () => {

            const currentYear = moment().format('YYYY');
            const startDate = `${currentYear}-01-01`;
            const endDate = `${currentYear}-12-31`;

            try {
                let url = process.env.REACT_APP_BASE_URL;
                const token = localStorage.getItem('authToken');
                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };

                // const response = await fetch(
                //     url +
                //     `/gantt_chart?start_date=${startDate}&end_date=${endDate}`,
                //     requestOptions
                // );
                const baseUrl = url + '/';
                const dateParameters = 'start_date=2022-10-25&end_date=2026-10-26';

                const endpoint = role === 'subContractor' ? 'sub_cont_gantt_chart' : 'gantt_chart';
                const fullUrl = `${baseUrl}${endpoint}?${dateParameters}`;
                const response = await fetch(fullUrl, requestOptions);

                const data = await response.json();
                setProjects(data.data)

                if (role === 'subContractor') {
                    var projectNames = data.data.map(project => project.user.company_name);
                    setProjectLabel(projectNames)
                } else {
                    var projectNames = data.data.map(project => project.assign_to.company_name);
                    setProjectLabel(projectNames)
                }

                const transformedData = data.data.map(project => [
                    project.project_start_date,
                    project.project_end_date
                ]);

                const taskCompleted = data.data.map(project => '100')

                const actualTaskCompleted = data.data.map(project => Number(project.status).toFixed(0));

                console.log(actualTaskCompleted)

                const calculateTargetDate = (startDate, endDate, status) => {
                    const startMoment = moment(startDate);
                    const endMoment = moment(endDate);
                    // Calculate the duration in days
                    const durationInDays = endMoment.diff(startMoment, 'days');
                    // Calculate the target date based on the status
                    const targetDate = startMoment.add((status / 100) * durationInDays, 'days');
                    // Ensure the target date is within the range of start and end dates
                    if (targetDate.isBefore(startMoment)) {
                        return startMoment.format('YYYY-MM-DD');
                    } else if (targetDate.isAfter(endMoment)) {
                        return endMoment.format('YYYY-MM-DD');
                    } else {
                        return targetDate.format('YYYY-MM-DD');
                    }
                };

                // Get the current date in the 'YYYY-MM-DD' format
                const currentDate = moment().format('YYYY-MM-DD');

                // Now, you can use the calculateTargetDate function for each project
                const targetDates = data.data.map(project => {
                    const startDate = project.project_start_date;
                    const endDate = project.project_end_date;
                    const status = project.status;

                    const targetDate = calculateTargetDate(startDate, endDate, status);

                    // Return an array with current date and target date
                    return [currentDate, targetDate];
                });


                console.log(targetDates);


                // const currentDate = moment();

                // const targetDates = data.data.map(project => [
                //     moment(project.project_start_date).isAfter(currentDate)
                //         ? currentDate.format('YYYY-MM-DD')
                //         : project.project_start_date,
                //     moment(project.project_end_date) >= currentDate
                //         ? currentDate.format('YYYY-MM-DD')
                //         : moment(project.project_end_date).format('YYYY-MM-DD')
                // ]);

                console.log(transformedData);

                initializeChart(projectNames, transformedData, targetDates, taskCompleted, actualTaskCompleted);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedTimeUnit]);


    const initializeChart = (labels, transformedData, targetDates, taskCompleted, actualTaskCompleted) => {

        const existingChart = Chart.getChart('myChart');
        if (existingChart) {
            existingChart.destroy();
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Project Timeline',
                    data: transformedData,
                    taskCompleted: taskCompleted,
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderColor:
                        'rgba(0, 0, 0, 1)',
                    borderWidth: 1,
                    // barPercentage: 0.8
                    borderSkipped: false
                },
                {
                    label: 'Actual Progress',
                    data: targetDates,
                    taskCompleted: actualTaskCompleted,
                    backgroundColor: 'rgba(255, 26, 104, 0.2)',
                    borderColor:
                        'rgba(255, 26, 104, 1)',
                    borderWidth: 1,
                    // barPercentage: 0.8
                    borderSkipped: false
                },
            ],
        };

        const currentYear = moment().format('YYYY');
        const startDate = `${currentYear}-01-01`;
        const endDate = `${currentYear}-12-31`;

        const config = {
            type: 'bar',
            data,
            options: {
                plugins: {
                    tooltip: {
                        enabled: false
                        // callbacks: {
                        //     label: (context) => {
                        //         console.log(context)
                        //         if (context.dataset.label == 'Project Time') {
                        //             return `Project Time : ${context.raw[0]} - ${context.raw[1]}`
                        //         } else {
                        //             return `Actual Time: ${context.raw[0]} - ${context.raw[1]}`
                        //         }
                        //     }
                        // }
                    },
                    datalabels: {
                        formatter: (value, context) => {
                            const taskPercentage = context.dataset.taskCompleted[context.dataIndex]
                            return `${taskPercentage}%`
                        }
                    }
                },
                indexAxis: 'y',
                scales: {
                    x: {
                        // min: startDate,
                        // max: endDate,
                        offset: false,
                        min: '2023-01-01',
                        max: '2024-12-12',
                        type: 'time',
                        time: {
                            unit: selectedTimeUnit,
                        },
                        ticks: {
                            align: 'start'
                        },
                        grid: {
                            borderDash: [5, 5]
                        },
                        position: 'top'
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
            plugins: [ChartDataLabels],
        };

        const myChart = new Chart(document.getElementById('myChart'), config);
    };

    return (
        <div className='container'>
            <div className="color_bg">
                <div className="project_detail">
                    <div>
                        <div className="chartCard">
                            <div className="chartBox">
                                <div className="form-group w-25">
                                    <label htmlFor="timeUnit">Select Time Unit: </label>
                                    <div className="custom-select">
                                        <select
                                            id="timeUnit"
                                            className="form-control"
                                            value={selectedTimeUnit}
                                            onChange={handleTimeUnitChange}
                                        >
                                            <option value="quarter">Quarter</option>
                                            <option value="month">Month</option>
                                        </select>
                                        <div className="dropdown-icon">&#9660;</div>
                                    </div>
                                </div>
                                <canvas id="myChart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
