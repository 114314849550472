import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Account from "./pages/account.jsx";
import Dashboard from "./pages/dashboard.jsx";
import LandingPage from "./pages/landingPage.jsx";
import Payment from "./pages/payment.jsx";
import SelectRole from "./pages/paymentPlans.jsx";
import SignIn from "./pages/signin.jsx";
import SignUp from "./pages/signup.jsx";
import ThankYou from "./pages/thankyou.jsx";
import { verifyAuthToken } from "./utils.js";
import ContactUs from "./pages/contactUs.jsx";
import FindProject from "./pages/findProject";
import Services from "./pages/services.jsx";
import Story from "./pages/story.jsx";
import { fetchUserProfileDetails } from "./store/userProfileSlice/userProfileSlice.js";
import SubContractor from "./pages/subContractor.jsx";
import Projectdetail from "./pages/projectdetail.jsx";
import Startprojectdetail from "./pages/startprojectdetail.jsx";
import Biderlist from "./pages/biderlist.jsx";
import SCsubmitProposaldetail from "./pages/SCsubmitProposaldetail.jsx";
import SCawardproposal from "./pages/SCawardproposal.jsx";
import Submitproposal from "./pages/submitproposal.jsx";
import PostNewProject from "./components/postNewProject.jsx";
import ResetPassword from "./pages/reset-password.jsx";
import MyContacts from "./components/myContacts.jsx";

const AppRouter = ({ isAuthenticated, setIsAuthenticated, paymentStatus }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const authenticated = !!localStorage.getItem("authToken");
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useSelector((state) => {
    return state?.userProfileSlice?.userData?.data?.role;
  });

  const userData = useSelector((state) => {
    return state?.userProfileSlice?.userData?.data;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthToken(fetchUserProfileDetails));
  }, [userRole, dispatch, paymentStatus]);

  useEffect(() => {
    if(location.pathname.includes("/project-details")){
      localStorage.setItem('redirect', location.pathname)
    }
    if (paymentStatus == "TRIAL_EXPIRED" || paymentStatus == "NEW") {
      if (location.pathname.includes("/account"))
        navigate("/payment", { replace: true });
    } else if (paymentStatus === "SUBSCRIBED") {
      if (location.pathname == "/payment") {
        navigate("/");
      }
    }
  }, [paymentStatus, location.pathname]);

  const paymentSripe = useSelector((state) => {
    return state?.userProfileSlice?.userData?.data?.stripe_customer_id;
  });
  useEffect(() => {
    // (isAuthenticated && );
    if (!authenticated) {
      if(location.pathname.includes("/change-password")){
        navigate(`/change-password${window.location.search}`);
      }else{
        navigate("/signin");
      }
      // if(window.location.search){
      //   navigate(`/signin${window.location.search}`);
      // }else{
      // }
      
    }
  }, [authenticated]);
  console.log(location.pathname, "location.pathname", window.location.search);
  return (
    <div>
      <Routes>
        {/* <Route  path="/find-a-project" element={<FindProject />} /> */}
        {userData && (
          <Route
            path="/find-a-project"
            element={
              authenticated ? (
                <FindProject />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
        )}
        {/* {userData && <Route path="/send-invitation/:id" element={authenticated ? <MyContacts /> : <Navigate to="/signin" replace />} />} */}
        {userData && (
          <Route
            path="/post-new-project"
            element={
              authenticated ? (
                <PostNewProject />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
        )}
        {userData && (
          <Route
            path="/project-details/:id"
            element={
              authenticated ? (
                <Startprojectdetail />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
        )}
        {userData && (
          <Route
            path="/project-details/:id/:activeProjects"
            element={
              authenticated ? (
                <Startprojectdetail />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
        )}
        {userData && (
          <Route
            path="/account/:tabId"
            exact
            element={
              userRole == "sub_contractor" ? <SubContractor /> : <Account />
            }
          />
        )}
        {userData && (
          <Route
            path="/submitproposal/:pid"
            element={<Submitproposal isAuthenticated={authenticated} />}
          />
        )}
        {userData && (
          <Route path="/reset-password" element={<ResetPassword />} />
        )}

        <Route
          path="/payment"
          element={
            paymentStatus === "SUBSCRIBED" ? (
              <Navigate to="/" replace />
            ) : (
              <Elements stripe={stripePromise}>
                <Payment
                  isAuthenticated={authenticated}
                  paymentStatus={paymentStatus}
                  setIsAuthenticated={setIsAuthenticated}
                />
              </Elements>
            )
          }
        />
        {/* <Route path="/pd" element={<Startprojectdetail isAuthenticated={isAuthenticated} />} /> */}
        <Route
          path="/biderlisting"
          element={<Biderlist isAuthenticated={authenticated} />}
        />
        <Route
          path="/scaward"
          element={<SCawardproposal isAuthenticated={authenticated} />}
        />
        <Route
          path="/subcontractorsubmitdetail"
          element={<SCsubmitProposaldetail isAuthenticated={authenticated} />}
        />
        <Route
          path="/payment-completion"
          element={
            <ThankYou isAuthenticated={authenticated} userRole={userRole} />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              isAuthenticated={authenticated}
              paymentSripe={paymentSripe}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default AppRouter;
