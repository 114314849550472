import React, { useEffect, useState } from 'react'
import DefaultLayout from '../reusableComponents/defaultLayout'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword({isAuthenticated}) {

    const [token, setToken] = useState('')
    const navigate = useNavigate();

    const initialValues = {
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // Get the current URL
        const urlParams = new URLSearchParams(window.location.search);

        // Get the value of the 'token' parameter
        const token = urlParams.get('token');

        if (token) {
            // Do something with the token, e.g., store it in state or perform an action
            console.log('Token:', token);
            setToken(token)
        }
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true); // Set submitting state when form submission begins
            const requestData = {
                new_password: values.password,
                token: token
            };
            let url = process.env.REACT_APP_BASE_URL;

            const response = await fetch(`${url}/update_forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const { data, message, success } = await response.json();
                if (success) {
                    toast.success(message, { autoClose: 3000 });
                    navigate('/')
                } else {
                    toast.error(message, { autoClose: 3000 });
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setSubmitting(false); // Ensure submitting state is set to false even if an error occurs
        }
    };

    return (
        <div>
            <DefaultLayout>
                <section className="title_head">
                    <div className="sign_up">
                        <h1>Change Password</h1>
                    </div>
                </section>
                <section className="form_style">
                    <div className="container">
                        <div className="color_bg">
                            <div className="sign_up">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, touched, errors }) => (
                                        <Form>
                                            <div className="mb-3 password-group">
                                                <label className="form-label">Password</label>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                    placeholder="Enter Password"
                                                />
                                                <ErrorMessage name="password" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3 password-group">
                                                <label className="form-label">Confirm Password</label>
                                                <Field
                                                    name="confirmPassword"
                                                    type="password"
                                                    className={`form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''
                                                        }`}
                                                    placeholder="Confirm Password"
                                                />
                                                <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                            </div>

                                            <div>
                                                <button type="submit" className="submit_btn" disabled={isSubmitting}>
                                                    Change Password{' '}
                                                    {isSubmitting && (
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </DefaultLayout>
        </div>
    )
}
