import React, { useState } from 'react'
import DefaultLayout from '../reusableComponents/defaultLayout'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export default function ResetPassword() {

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword1(!showPassword1);
    };
    const togglePasswordVisibility3 = () => {
        setShowPassword2(!showPassword2);
    };
    const initialValues = {
        currentPassword: '',
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required('Current password is required')
            .min(6, 'Password must be at least 6 characters'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const token = localStorage.getItem('authToken');

        try {
            setSubmitting(true); // Set submitting state when form submission begins
            const baseOrigin = window.location.origin;
            const requestData = {
                current_password: values.currentPassword,
                new_password: values.password,
            };
            let url = process.env.REACT_APP_BASE_URL;

            const response = await fetch(`${url}/update_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const { data, message, success } = await response.json();
                if (success) {
                    toast.success(message, { autoClose: 3000 });
                    // localStorage.setItem('authToken', data?.token);
                } else {
                    toast.error(message, { autoClose: 3000 });
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setSubmitting(false); // Ensure submitting state is set to false even if an error occurs
        }
    };

    return (
        <div>
            <DefaultLayout>
                <section className="title_head">
                    <div className="sign_up">
                        <h1>Reset Password</h1>
                    </div>
                </section>
                <section className="form_style">
                    <div className="container">
                        <div className="color_bg">
                            <div className="sign_up">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, touched, errors }) => (
                                        <Form>
                                            <div className="mb-3 password-group">
                                                <label className="form-label">Current Password</label>
                                                <div className='password-input-container'>
                                                    <Field
                                                        name="currentPassword"
                                                        type={showPassword ? "text" : "password"}
                                                        className={`form-control`}
                                                        placeholder="Enter Password"
                                                    />
                                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                                        {showPassword ? <FiEyeOff /> : <FiEye />}
                                                    </span>
                                                </div>
                                                <ErrorMessage name="currentPassword" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3 password-group">
                                                <label className="form-label">New Password</label>
                                                <div className='password-input-container'>
                                                    <Field
                                                        name="password"
                                                        type={showPassword1 ? "text" : "password"}
                                                        className={`form-control`}
                                                        placeholder="Enter Password"
                                                    />
                                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility2}>
                                                        {showPassword1 ? <FiEyeOff /> : <FiEye />}
                                                    </span>
                                                </div>
                                                <ErrorMessage name="password" component="div" className="text-danger" />
                                            </div>

                                            <div className="mb-3 password-group">
                                                <label className="form-label">Confirm Password</label>
                                                <div className='password-input-container'>
                                                    <Field
                                                        name="confirmPassword"
                                                        type={showPassword2 ? "text" : "password"}
                                                        className={`form-control`}
                                                        placeholder="Confirm Password"
                                                    />
                                                    <span className="password-toggle-icon" onClick={togglePasswordVisibility3}>
                                                        {showPassword2 ? <FiEyeOff /> : <FiEye />}
                                                    </span>
                                                </div>
                                                <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                            </div>

                                            <div>
                                                <button type="submit" className="submit_btn" disabled={isSubmitting}>
                                                    Change Password{' '}
                                                    {isSubmitting && (
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </DefaultLayout>
        </div>
    )
}
