import React, { useState, useEffect } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment/moment';
const FileLogo = '/assets/images/file.png';


export default function OutForBid() {
    let navigate = useNavigate()
    const [projects, setprojects] = useState([]),
        [count, setCount] = useState(0),
        [pageCount, setPageCount] = useState(0),
        [loading, setLoading] = useState(true),
        [error, setError] = useState(false);
    const fetchData = async () => {
        setLoading(true); // Start loading

        let url = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem('authToken');
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(
                url + `/my_project_list?page_num_start=1&page_size=10`,
                requestOptions
            );

            if (response.ok) {
                const data = await response.json();

                if (data?.data?.projects.length > 0) {
                    setCount(data.data.projectsCount / 10);
                    setPageCount(data.data.projectsCount);
                    setprojects(data.data.projects);
                } else {
                    setError(true); // Set an error state if no data is available
                }
            } else {
                setError(true); // Set an error state if the response is not ok
            }
            setLoading(false); // Stop loading
        } catch (error) {
            setError(true); // Set an error state if an error occurs
            setLoading(false); // Stop loading
        }
    };
    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [])

    const fetchPaginatedData = async (currentPage) => {
        let url = process.env.REACT_APP_BASE_URL;
        setLoading(true)
        const token = localStorage.getItem('authToken');
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const res = await fetch(
            url +
            `/my_project_list?page_num_start=${currentPage}&page_size=10`,
            requestOptions
        );
        const data = await res.json();
        setLoading(false)
        return data;
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        const dataFromServer = await fetchPaginatedData(currentPage);
        setprojects(dataFromServer?.data?.projects);
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const [selectedOptions, setSelectedOptions] = useState({});

    return (
        // <div > //className="tab-pane fade bid_tab" id="home" role="tabpanel" aria-labelledby="home-tab"
        <div className='container'>
            <div className="about_projects">
                {loading ?
                    <div className="text-center loader_style">
                        <ThreeDots
                            height="100"
                            width="120"
                            radius="9"
                            color="#000"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div> : (
                        (projects?.length == 0) ?
                            <div>
                                <div className="text-center">
                                    <h3>No projects to show.</h3>
                                </div>
                            </div> :
                            <div className="color_bg">
                                <ul className="project_boxes">
                                    {projects.map((project, index) => (
                                        <li>
                                            <div className="project_detail" key={index}>
                                                <div className="project_head">
                                                    <h2 onClick={() => { navigate(`/project-details/${project.id}`) }} className='cursor-pointer' >{project.project_name.length > 50 ? `${project.project_name.slice(0, 50)}...` : project.project_name}<span> {moment(project.project_start_date).format("MMM DD, YYYY")}</span></h2>
                                                    <ul className="project_status">
                                                        {/* <li>
                                                                <div className='list_btn'>
                                                                    <a href=""><img src="assets/images/list.gif" alt="" /></a>
                                                                </div>
                                                            </li> */}
                                                        {/* <li>
                                                            <div>
                                                                <p
                                                                    aria-label="more"
                                                                    id="long-button"
                                                                    aria-controls={open ? 'long-menu' : undefined}
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    aria-haspopup="true"
                                                                    onClick={(e) => handleClick(e, project)}
                                                                    className='cursor-pointer'
                                                                >
                                                                    Job Status: <span>{project.jobStatus}</span>
                                                                </p>
                                                                <Menu
                                                                    id="long-menu"
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'long-button',
                                                                    }}
                                                                    anchorEl={selectedOptions[project.id]}
                                                                    open={Boolean(selectedOptions[project.id])}
                                                                    onClose={() => handleClose(options[0].value, project)}
                                                                    PaperProps={{
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                                            width: '20ch',
                                                                        },
                                                                    }}
                                                                >
                                                                    {options.map((option) => (
                                                                        <MenuItem
                                                                            key={option.value}
                                                                            selected={option.value === project.jobStatus}
                                                                            onClick={() => { handleClose(option.value, project) }}
                                                                        >
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Menu>
                                                            </div>
                                                        </li> */}
                                                        <li>
                                                            <p className="view_count mb-2 pb-1"><img src="/assets/images/view.png" className='mb-1' alt="" /><span>{project.bids_count}</span></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p><strong>Project Description:</strong>{project.project_description}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                    )}
                {pageCount > 10 && <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={count}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"item-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"item-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"item-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"item-link"}
                    activeClassName={"active"}
                />}


                <div className="creat_btn text-center mt-5">
                    <Link to="/find-a-project" >Find a Project</Link>
                    <Link to="/post-new-project" className="ms-3">Post a Project</Link>
                </div>
            </div>
        </div>
    )
}
