import React, { useState, useEffect } from "react";
import DefaultLayout from "../reusableComponents/defaultLayout";
import OutForBid from "../components/outForBid";
import ActiveProjects from "../components/activeProjects";
import GenttChart from "../components/genttChart";
import MyContacts from "../components/myContacts";
import Profile from "../components/profile";
import Upload from "../components/uploadFile";
import Welcome from "../reusableComponents/welcome";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useSelector } from 'react-redux';

function Account() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);
  const navigate = useNavigate();
  // const queryParams = new URLSearchParams(location.search);
  const { tabId } = useParams();

  const userRole = useSelector((state) => {
    return state?.userProfileSlice?.userData?.data?.role;
});

  useEffect(() => {
    const initialTabId = 0 <= tabId && tabId <= 5 ? parseInt(tabId) : 0;
    // const initialTabId = parseInt(queryParams.get('tabId')) || 0; // Get initial tabId from the URL query or default to 1
    setActiveTab(initialTabId);
  }, [activeTab]);

  // Function to handle tab changes
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    navigate(`/account/${tabId}`);
  };
console.log(userRole, "Roleeee");
  return (
    <>
      <DefaultLayout>
        <Welcome />
        <section className="gc_homebanner">
          <div className="container">
            <div className="globle_tabs">
              <ul className="nav nav-tabs" id="gcTab" role="tablist">
                <li role="presentation cursor-pointer">
                  <a
                    // id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"
                    onClick={() => handleTabChange(1)}
                    className={`nav-link cursor-pointer ${
                      activeTab === 1 ? "active" : ""
                    }`}
                  >
                    Out for Bid
                  </a>
                </li>
                <li role="presentation">
                  <a
                    onClick={() => handleTabChange(2)}
                    className={`nav-link cursor-pointer ${
                      activeTab === 2 ? "active" : ""
                    }`}
                  >
                    Active Projects
                  </a>
                </li>
                {userRole !== "owner" &&  
                <li role="presentation">
                  <a
                    // id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"
                    onClick={() => handleTabChange(3)}
                    className={`nav-link cursor-pointer ${
                      activeTab === 3 ? "active" : ""
                    }`}
                  >
                    Sub Contractor Schedule
                  </a>
                </li>
                }
               
                <li role="presentation">
                  <a
                    // id="contact-tab" data-bs-toggle="tab" data-bs-target="#mycontact" type="button" role="tab" aria-controls="contact" aria-selected="false"
                    onClick={() => handleTabChange(4)}
                    className={`nav-link cursor-pointer ${
                      activeTab === 4 ? "active" : ""
                    }`}
                  >
                    My Contacts
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="gcTabContent"></div>
            </div>
          </div>
        </section>
        {activeTab === 0 ? (
          <Profile />
        ) : activeTab === 1 ? (
          <OutForBid />
        ) : activeTab === 2 ? (
          <ActiveProjects />
        ) : activeTab === 3 ? (
          <GenttChart role="other" />
        ) : activeTab === 4 ? (
          <MyContacts onTabChange={handleTabChange} />
        ) : (
          activeTab === 5 && <Upload onTabChange={handleTabChange}/>
        )}
      </DefaultLayout>
    </>
  );
}

export default Account;
