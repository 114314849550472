import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ColorRing } from 'react-loader-spinner';
import CloseIcon from '@mui/icons-material/Close';

import DefaultLayout from '../reusableComponents/defaultLayout';
import { useSelector } from 'react-redux';

const AddMoreLogo = 'assets/images/pluscircle.png';

export default function Submitproposal() {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const { pid } = useParams();

    const planDocument = useRef(null);
    const additionalDocument = useRef(null);

    const [selectedPlanDoc, setSelectedPlanDoc] = useState([]);
    const [selectedAdditionalDoc, setSelectedAdditionalDoc] = useState([]);
    const [goBack, setGoBack] = useState(false);
    const handlePlanDocChange = (event, setFieldValue) => {
        const selectedDoc = Array.from(event.currentTarget.files);
        setSelectedPlanDoc(prevDocs => [...prevDocs, ...selectedDoc]);
        setFieldValue("plan_doc", selectedDoc);
    };

    const handleAdditionalDocChange = (event, setFieldValue) => {
        const selectedDoc = Array.from(event.currentTarget.files);
        setSelectedAdditionalDoc(prevDocs => [...prevDocs, ...selectedDoc]);
        setFieldValue("additional_doc", selectedDoc);
    };

    const handleDeleteDocument = (event, index, documentType, setFieldValue) => {
        event.preventDefault();
        if (documentType === "plan_doc") {
            const updatedDocs = [...selectedPlanDoc];
            updatedDocs.splice(index, 1);
            setSelectedPlanDoc(updatedDocs);
            setFieldValue("plan_doc", updatedDocs);
        } else if (documentType === "additional_doc") {
            const updatedDocs = [...selectedAdditionalDoc];
            updatedDocs.splice(index, 1);
            setSelectedAdditionalDoc(updatedDocs);
            setFieldValue("additional_doc", updatedDocs);
        }
    };

    const initialValues = {
        plan_doc: [],
        additional_doc: [],
        project_description: '',
    };

    const validationSchema = Yup.object().shape({
        project_description: Yup.string().required("Description is required"),
    });

    const submitProposal = async (values) => {
        setLoading(true);
        console.log({ values, pid });

        let url = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem('authToken');

        if (token) {
            try {
                let formData = new FormData();

                selectedPlanDoc.forEach((plan) => {
                    formData.append("plan_doc[]", plan);
                });

                [...values.additional_doc].forEach((additional) => {
                    formData.append("additional_doc[]", additional);
                });

                formData.append("project_id", pid);
                values.project_description?.length && formData.append('description', values.project_description);

                const requestOptions = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                };
                const data = fetch(
                    url + `/create_bid`,
                    requestOptions
                ).then((response) => response.json())
                    .then(({ data, message, success }) => {
                        if (success) {
                            toast.success('Proposal submitted successfully!', { autoClose: 3000 });
                            navigate('/account/2')
                        } else {
                            toast.error(message, { autoClose: 3000 });
                        }
                    }).finally(() => {
                        setLoading(false);
                    });
            } catch (error) {
                console.error('An error occurred:', error);
                toast.error('An error occurred.' + error, { autoClose: 3000 });
                setLoading(false);
            }
        }
    };

    const role = useSelector((state) => {
        return state?.userProfileSlice?.userData?.data?.role;
    });

    return (
        <DefaultLayout>
            <div>
                <section className="inner_banner account_banner">
                    <div className="inner_plan_banner">
                        <div className="container">

                            {role == 'owner' ? <h1>{role.split('_')[0].charAt(0).toUpperCase() + role.split('_')[0].slice(1)} Submit Proposal</h1> : <h1>{role.split('_')[0].charAt(0).toUpperCase() + role.split('_')[0].slice(1)} {role.split('_')[1].charAt(0).toUpperCase() + role.split('_')[1].slice(1)} Submit Proposal</h1>}
                        </div>
                    </div>
                </section>
                <div className="new_project project_name_banner py-5">
                    <div className='container'>
                        <button className='back-btn mb-3' onClick={() => setGoBack(() => {
                            navigate(-1);
                            return true;
                        })}>Go Back</button>
                        <div className="color_bg mb-0">
                            <h2>Profile Information</h2>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={submitProposal}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className="form_style ps-0">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput12" className="form-label">Proposal Documents (PDF format only) </label>
                                                        <div className="upload_files">
                                                            <input
                                                                type="file"
                                                                name="plan_doc"
                                                                multiple
                                                                accept=".pdf"
                                                                ref={planDocument}
                                                                onChange={(event) => handlePlanDocChange(event, setFieldValue)}
                                                                title="Choose file"
                                                            />
                                                            <ErrorMessage name="plan_doc" component="div" className="text-danger" />
                                                        </div>
                                                        {selectedPlanDoc.length > 0 && (
                                                            <div className="d-flex selected-docs">
                                                                {selectedPlanDoc.map((plan, index) => (
                                                                    <div key={index} className="selected-doc">
                                                                        <p className="mb-0 mr-2">
                                                                            {plan.name.length > 20 ? `${plan.name.slice(0, 20)}...` : plan.name}
                                                                        </p>
                                                                        <button className="delete-docs"
                                                                            onClick={(event) => handleDeleteDocument(event, index, "plan_doc", setFieldValue)}>
                                                                            <CloseIcon fontSize='20px' />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput12" className="form-label">Additional Proposal Documents</label>
                                                        <div className="upload_files">
                                                            <input
                                                                type="file"
                                                                name="additional_doc"
                                                                multiple
                                                                accept=".pdf"
                                                                ref={additionalDocument}
                                                                onChange={(event) => handleAdditionalDocChange(event, setFieldValue)}
                                                                title="Choose file"
                                                            />
                                                            <ErrorMessage name="additional_doc" component="div" className="text-danger" />
                                                        </div>
                                                        {selectedAdditionalDoc.length > 0 && (
                                                            <div className="d-flex selected-docs">
                                                                {selectedAdditionalDoc.map((doc, index) => (
                                                                    <div key={index} className="selected-doc">
                                                                        <p className="mb-0 mr-2">
                                                                            {doc.name.length > 20 ? `${doc.name.slice(0, 20)}...` : doc.name}
                                                                        </p>
                                                                        <button className="delete-docs"
                                                                            onClick={(event) => handleDeleteDocument(event, index, "additional_doc", setFieldValue)}>
                                                                            <CloseIcon fontSize='20px' />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='mb-3 text-center'>
                                                        <div className='upload_file_btn'>
                                                            <img src={AddMoreLogo} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="form_style ps-0">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleFormControlInput12" className="form-label">Comments for Project Owner</label>
                                                        <Field as="textarea" className="form-control" id="project_description" name="project_description" />
                                                        <ErrorMessage name="project_description" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="creat_btn text-center">
                                            <button type="submit" className='globle_submit'>Submit Proposal {loading && <ColorRing
                                                visible={true}
                                                height="35"
                                                width="35"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperclassName="blocks-wrapper"
                                                colors={['white', 'white', 'white', 'white', 'white', 'white']}
                                            />}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}
