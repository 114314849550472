import React, { useState } from "react";
import { CloudUpload } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

export default function Upload({ onTabChange }) {
  const [loading, setLoading] = useState(false);
  const handleFileUpload = async (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const url = process.env.REACT_APP_BASE_URL;
      const token = localStorage.getItem("authToken");
      const requestData = new FormData();
      requestData.append("contact_file", file);
      const response = await fetch(`${url}/upload_contact`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: requestData,
      });
      if (response.ok) {
        const { data, message, success } = await response.json();
        console.log(data, "here is data", message);
        // if (success) {
        toast.success(message, { autoClose: 3000 });
        setLoading(false);
        onTabChange(4);
        //   }
        // } else {
        //   throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred." + error, { autoClose: 3000 });
    }
    };

  const handleFileDownload = async () => {
    try {
      setLoading(true);
      let url = process.env.REACT_APP_BASE_URL;
      const token = localStorage.getItem("authToken");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await fetch(url + `/contact_template`, requestOptions);
      const data = await res.json();
      let downloadUrl = data ? data.data.path : "";
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.download = "template.csv";
      downloadLink.href = window.URL.createObjectURL(blob);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="container">
      <div className="about_projects">
        <div className="color_bg">
          {loading ? (
            <div className="text-center loader_style " colSpan="12">
              <ThreeDots
                height="100"
                width="120"
                radius="9"
                color="#B1903F"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <div className="contact_list">
              <h2>Step 1: Upload Spreadsheet:</h2>
              <div className="add_contact">
                <div className="file-actions">
                  <label htmlFor="file-upload" className="upload-label">
                    <CloudUpload />
                    <div>Choose File</div>
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
              <div className="download-section">
                <p>Don't have a file? Download it from below:</p>
                <button onClick={handleFileDownload} className="download-btn">
                  Download CSV File
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
