import React, { useEffect } from "react";
import DefaultLayout from "../reusableComponents/defaultLayout";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import Notes from "../components/notes";
import Rating from "../components/rating";
import { ThreeDots } from "react-loader-spinner";
import Ratting from "../components/rating";
import Chat from "../components/chat";
const FileLogo = "/assets/images/file.png";
const Loader = () => <div>Loading...</div>;

export default function Startprojectdetail() {
    const [projectsDetials, setProjectsDetials] = useState({});
    const [openNotesIndex, setOpenNotesIndex] = useState(null);
    const [openChatIndex, setOpenChatIndex] = useState(null);
    const [isUser, setIsUser] = useState(false);
    const [projectsBidding, setBiddingDetials] = useState([]);
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const [awarded, setAwarded] = useState(false);
    const [isNotesAdded, setisNotesAdded] = useState(false);
    const [goBack, setGoBack] = useState(false);
    const [chatRefresh, setChatRefresh] = useState(false);

    const navigate = useNavigate(); // Use useNavigate hook

    const location = useLocation();
    const [searchParams] = useSearchParams();

    // Get a specific query parameter
    const isPending = searchParams.get('pending');

    console.log(isPending)

    const handleRefresh = () => {
        // Add the logic to refresh your parent component here
        console.log("Parent component refreshed!");
        setChatRefresh(true);
    };

    // useEffect(() => {
    //     if (goBack) {
    //         navigate(-1);
    //     }
    // }, [goBack])

    const updateFlag = (notesAdded) => {
        setisNotesAdded(notesAdded);
    };

    const showNotes = (index) => {
        setOpenChatIndex(null);
        setOpenNotesIndex(index);
    };

    const userId = useSelector((state) => {
        return state?.userProfileSlice?.userData?.data?.id;
    });

    console.log(userId)


    const showChat = (index) => {
        setOpenChatIndex(index);
        setOpenNotesIndex(null);
    };

    const showChatForActiveBid = () => {
        setOpenChatIndex(1);
        setOpenNotesIndex(null);
    };

    const showNotesForActiveBid = () => {
        setOpenChatIndex(null);
        setOpenNotesIndex(1);
    };

    const { id, activeProjects } = useParams();

    const fetchProjectData = async () => {
        setLoading(true); // Set loading to true before initiating the fetch
        let url = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem("authToken");
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(
                url + `/project_detail/${id}`,
                requestOptions
            );
            if (!response.ok) {
                throw new Error("Failed to fetch project details");
            }
            const data = await response.json();
            console.log({ data });
            setProjectsDetials(data?.data);
        } catch (error) {
            console.error("Error fetching project details:", error);
        } finally {
            setLoading(false); // Set loading back to false after data is fetched
        }
    };

    const fetchBiddingData = async () => {
        let url = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem("authToken");
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const data = fetch(url + `/bid_listing?project_id=${id}`, requestOptions)
            .then(async (res) => {
                let body = await res.json();
                console.log({ body });
                setBiddingDetials(body?.data);
            })
            .catch((err) => { });
        return data;
    };

    const handleAward = async (item, index) => {
        try {
            setLoadingIndex(index);
            setAwarded(true);
            let url = process.env.REACT_APP_BASE_URL;
            const token = localStorage.getItem("authToken");
            const requestData = {
                project_id: item.project_id,
                bid_id: item.id,
                assigned_to: item.user_id,
            };

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            };

            const res = await fetch(url + `/accept_bid`, requestOptions);

            const body = await res.json();

            if (body.success === true) {
                toast.success("Bid awarded successfully!", { autoClose: 3000 });
            }

            console.log({ body });
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingIndex(null);
        }
    };

    useEffect(() => {
        fetchProjectData();
        setTimeout(() => {
            fetchBiddingData();
        }, 1000);
    }, [isNotesAdded, awarded, chatRefresh]);

    useEffect(() => {
        if (projectsDetials?.user_id == userId) {

            setIsUser(true);
        }
    }, [isUser, projectsDetials]);

    const getFileNameFromURL = (url) => {
        // Use the URL API to extract the filename from the URL
        const urlObj = new URL(url);
        const fileNameWithExtension = urlObj.pathname.split("/").pop();
        // Skip the first 10 characters in the filename
        const fileNameWithoutPrefix = fileNameWithExtension.slice(13);
        // Further split the filename to remove the extension
        const fileNameWithoutExtension = fileNameWithoutPrefix.split(".")[0];
        // You can further manipulate or format the filename as needed
        return fileNameWithoutExtension.length > 35
            ? `${fileNameWithoutExtension.slice(0, 35)}...`
            : fileNameWithoutExtension;
    };

    const Handlenavigate = () => {
        navigate(`/account/4?${id}`);
    };

    return (
        <DefaultLayout>
            <div>
                <section className="inner_banner account_banner">
                    <div className="inner_plan_banner">
                        <div className="container">
                            <h1>Project Detail</h1>
                        </div>
                    </div>
                </section>
                {loading ? (
                    <div className="new_project project_name_banner my-5 pt-3">
                        <div className="container mx-auto">
                            <div className="color_bg mb-0 mx-auto">
                                <div className="text-center loader_style">
                                    <ThreeDots
                                        height="100"
                                        width="120"
                                        radius="9"
                                        color="#000"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="new_project project_name_banner my-5 pt-3">
                        <div className="container">
                            <button
                                className="back-btn mb-3"
                                onClick={() => {
                                    navigate(-1), setGoBack(true);
                                }}
                            >
                                Go Back
                            </button>
                            <div className="color_bg mb-0">
                                <h2>
                                    {projectsDetials?.project_name?.length > 50
                                        ? `${projectsDetials.project_name.slice(0, 50)}...`
                                        : projectsDetials.project_name || "N/A"}
                                </h2>
                                <form action="">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form_style ps-0">
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput1"
                                                        className="form-label"
                                                    >
                                                        Project Name
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.project_name?.length > 50
                                                            ? `${projectsDetials.project_name.slice(
                                                                0,
                                                                50
                                                            )}...`
                                                            : projectsDetials.project_name || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Email
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.email || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput11"
                                                        className="form-label"
                                                    >
                                                        Project Rep
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.project_rep || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput13"
                                                        className="form-label"
                                                    >
                                                        Phone Number
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.phone || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput13"
                                                        className="form-label"
                                                    >
                                                        Project Description
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.project_description || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput13"
                                                        className="form-label"
                                                    >
                                                        Who have you worked with in the past? (Banks,
                                                        Lenders, etc.)
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.past_work || "N/A"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form_style">
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput1"
                                                        className="form-label"
                                                    >
                                                        Designer
                                                    </label>
                                                    <p className="text">
                                                        {(projectsDetials?.designer == "design_build"
                                                            ? projectsDetials?.designer
                                                                .split("_")[0]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.designer
                                                                .split("_")[0]
                                                                ?.slice(1) +
                                                            " " +
                                                            projectsDetials?.designer
                                                                .split("_")[1]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.designer
                                                                .split("_")[1]
                                                                ?.slice(1)
                                                            : projectsDetials?.designer) || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Engineer
                                                    </label>
                                                    <p className="text">
                                                        {(projectsDetials?.engineer == "design_build"
                                                            ? projectsDetials?.engineer
                                                                .split("_")[0]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.engineer
                                                                .split("_")[0]
                                                                ?.slice(1) +
                                                            " " +
                                                            projectsDetials?.engineer
                                                                .split("_")[1]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.engineer
                                                                .split("_")[1]
                                                                ?.slice(1)
                                                            : projectsDetials?.engineer) || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Architect
                                                    </label>
                                                    <p className="text">
                                                        {(projectsDetials?.architect == "design_build"
                                                            ? projectsDetials?.architect
                                                                .split("_")[0]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.architect
                                                                .split("_")[0]
                                                                ?.slice(1) +
                                                            " " +
                                                            projectsDetials?.architect
                                                                .split("_")[1]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.architect
                                                                .split("_")[1]
                                                                ?.slice(1)
                                                            : projectsDetials?.architect) || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Permits
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.permit_doc?.map((data, index) => (
                                                            <div key={index}>
                                                                <span>
                                                                    <img src={FileLogo} alt="" />{" "}
                                                                    <a
                                                                        href={data.document_name}
                                                                        target="blank"
                                                                        style={{ color: "black" }}
                                                                    >
                                                                        {getFileNameFromURL(data.document_name) ||
                                                                            "N/A"}
                                                                    </a>
                                                                </span>
                                                                <br />
                                                            </div>
                                                        ))}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Financing
                                                    </label>
                                                    <p className="text">
                                                        {(projectsDetials?.financing == "design_build"
                                                            ? projectsDetials?.financing
                                                                .split("_")[0]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.financing
                                                                .split("_")[0]
                                                                ?.slice(1) +
                                                            " " +
                                                            projectsDetials?.financing
                                                                .split("_")[1]
                                                                ?.charAt(0)
                                                                ?.toUpperCase() +
                                                            projectsDetials?.financing
                                                                .split("_")[1]
                                                                ?.slice(1)
                                                            : projectsDetials?.financing) || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput12"
                                                        className="form-label"
                                                    >
                                                        Plans
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.plan || "N/A"}
                                                    </p>
                                                    <div className="upload_files border-0">
                                                        <label
                                                            for="exampleFormControlInput12"
                                                            className="form-label"
                                                        >
                                                            Uploaded Plans
                                                        </label>
                                                        <p className="text">
                                                            {projectsDetials?.plan_doc?.map((data, index) => (
                                                                <div key={index}>
                                                                    <span>
                                                                        <img src={FileLogo} alt="" />{" "}
                                                                        <a
                                                                            href={data.document_name}
                                                                            target="blank"
                                                                            style={{ color: "black" }}
                                                                        >
                                                                            {getFileNameFromURL(data.document_name) ||
                                                                                "N/A"}
                                                                        </a>
                                                                    </span>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                            {/* <span><img src={FileLogo} alt="" /> {projectsDetials?.plan_doc || 'N/A'}</span> */}
                                                        </p>
                                                        <label
                                                            for="exampleFormControlInput12"
                                                            className="form-label"
                                                        >
                                                            Uploaded Pictures
                                                        </label>
                                                        <div className="text">
                                                            <ul className="picture_list">
                                                                {projectsDetials?.plan_image?.map(
                                                                    (data, index) => (
                                                                        <li>
                                                                            <div className="pro_img">
                                                                                <a
                                                                                    href={data.image_name}
                                                                                    target="_blank"
                                                                                >
                                                                                    <img src={data.image_name} alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                )}
                                                                {/* */}
                                                            </ul>
                                                            <span className="del_btn">
                                                                <img src="assets/images/del.png" alt="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="form_style">
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput1"
                                                        className="form-label"
                                                    >
                                                        Approximate Start Date
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.project_start_date || "N/A"}
                                                    </p>
                                                </div>
                                                <div className="mb-3">
                                                    <label
                                                        for="exampleFormControlInput1"
                                                        className="form-label"
                                                    >
                                                        Approximate Completion Date
                                                    </label>
                                                    <p className="text">
                                                        {projectsDetials?.project_end_date || "N/A"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isUser == false && activeProjects !== "true" && (
                                        <div className="creat_btn text-center">
                                            <Link to={`/submitproposal/${projectsDetials?.id}`}>
                                                Submit Proposal For this Project
                                            </Link>
                                        </div>
                                    )}
                                </form>

                                {/* Awarded proposals code block */}
                                {projectsDetials?.award_bid !== null &&
                                    activeProjects == "true" && (
                                        <h3 className="sub_head">Awarded Proposals</h3>
                                    )}
                                {projectsDetials?.award_bid !== null &&
                                    activeProjects == "true" && (
                                        <ul className="proposal_list">
                                            <li>
                                                <div className="propocal_card dropdown">
                                                    <div className="color_bg">
                                                        <div className="project_head">
                                                            <h2>{projectsDetials.award_bid?.user?.fname}</h2>{" "}
                                                            <span>
                                                                Submitted{" "}
                                                                {moment(
                                                                    projectsDetials.award_bid?.created_at
                                                                ).format("MMMM D, YYYY")}
                                                            </span>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="proposal_content">
                                                                    <p>
                                                                        <strong>Comments: </strong>{" "}
                                                                        {projectsDetials.award_bid?.description
                                                                            ? projectsDetials.award_bid?.description
                                                                            : "N/A"}{" "}
                                                                    </p>
                                                                </div>
                                                                <div className="proposal_content">
                                                                    <p
                                                                        className="dropdown-toggle lastest-communication"
                                                                        onClick={() => showChatForActiveBid()}
                                                                    >
                                                                        <strong>Latest Communication </strong>
                                                                    </p>
                                                                    <p>
                                                                        {projectsDetials.award_bid?.latest_chat
                                                                            ?.message
                                                                            ? projectsDetials.award_bid?.latest_chat
                                                                                ?.message.length > 200
                                                                                ? `${projectsDetials.award_bid?.latest_chat?.message.slice(
                                                                                    0,
                                                                                    200
                                                                                )}...`
                                                                                : projectsDetials.award_bid?.latest_chat
                                                                                    ?.message
                                                                            : "N/A"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="proposal_content proposal_detail">
                                                                    <p className="mb-2">
                                                                        <strong>Proposal Documents</strong>
                                                                    </p>
                                                                    <ul className="proposal_files">
                                                                        {projectsDetials?.award_bid?.files?.length >
                                                                            0 ? (
                                                                            projectsDetials?.award_bid?.files?.map(
                                                                                (file, index) => (
                                                                                    <li key={index}>
                                                                                        <a
                                                                                            className="text"
                                                                                            href={file?.plan_doc}
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span>
                                                                                                <img
                                                                                                    src="/assets/images/file.png"
                                                                                                    alt=""
                                                                                                    className="mb-1 me-2"
                                                                                                />
                                                                                                {getFileNameFromURL(
                                                                                                    file?.plan_doc
                                                                                                )}
                                                                                            </span>
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <>
                                                                                <p>N/A</p>
                                                                            </>
                                                                        )}
                                                                    </ul>

                                                                    <p className="mb-2">
                                                                        <strong>Additional Documents</strong>
                                                                    </p>
                                                                    <ul className="proposal_files">
                                                                        {projectsDetials?.award_bid?.additionalfiles?.length > 0 ? (
                                                                            projectsDetials?.award_bid?.additionalfiles?.map((file, index) => (
                                                                                <li key={index}>
                                                                                    <a
                                                                                        className="text"
                                                                                        href={file?.additional_doc}
                                                                                        target="_blank"
                                                                                    >
                                                                                        <span>
                                                                                            <img
                                                                                                src="/assets/images/file.png"
                                                                                                alt=""
                                                                                                className="mb-1 me-2"
                                                                                            />
                                                                                            {getFileNameFromURL(
                                                                                                file?.additional_doc
                                                                                            )}
                                                                                        </span>
                                                                                    </a>
                                                                                </li>
                                                                            ))
                                                                        ) : (
                                                                            <>
                                                                                <p>N/A</p>
                                                                            </>
                                                                        )}
                                                                    </ul>

                                                                    <div className="award_review">
                                                                        <Ratting
                                                                            bidData={projectsDetials.award_bid}
                                                                        />
                                                                        <div className="notes w-50">
                                                                            <div className="proposal_content">
                                                                                <p
                                                                                    className="mb-1 dropdown-toggle"
                                                                                    onClick={() =>
                                                                                        showNotesForActiveBid()
                                                                                    }
                                                                                >
                                                                                    <strong>Notes </strong>
                                                                                </p>
                                                                                <p className="">
                                                                                    {projectsDetials.award_bid
                                                                                        ?.latest_notes?.notes
                                                                                        ? projectsDetials.award_bid
                                                                                            ?.latest_notes?.notes
                                                                                        : "N/A"}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {projectsDetials.award_bid?.status ==
                                                                        "hired" ? (
                                                                        <div className="create_btn">
                                                                            <button
                                                                                onClick={() =>
                                                                                    handleAward(projectsDetials.award_bid)
                                                                                }
                                                                                className="awarded_btn"
                                                                                disabled={true}
                                                                            >
                                                                                {"Awarded"}
                                                                            </button>
                                                                        </div>
                                                                    ) : projectsDetials.award_bid?.status ==
                                                                        "pending" ? (
                                                                        <div className="create_btn">
                                                                            <button
                                                                                onClick={() =>
                                                                                    handleAward(projectsDetials.award_bid)
                                                                                }
                                                                                className="globle_btn"
                                                                                disabled={loadingIndex === index}
                                                                            >
                                                                                {"Award Project"}
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="create_btn">
                                                                            <button
                                                                                onClick={() =>
                                                                                    handleAward(projectsDetials.award_bid)
                                                                                }
                                                                                className="rejected_btn"
                                                                                disabled={true}
                                                                            >
                                                                                {"Rejected"}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {openChatIndex == 1 && (
                                                        <Chat
                                                            setOpenChatIndex={setOpenChatIndex}
                                                            bidData={projectsDetials.award_bid}
                                                        />
                                                    )}
                                                    {openNotesIndex === 1 && (
                                                        <Notes
                                                            setOpenNotesIndex={setOpenNotesIndex}
                                                            bidData={projectsDetials.award_bid}
                                                            updateFlag={updateFlag}
                                                        />
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    )}



                                {/* SUBMITTED PROPOSALS CODE BLOCK */}
                                {isUser && (
                                    <>
                                        {!projectsDetials?.assigned_to && (
                                            <div className="text-center">
                                                <button
                                                    className="back-btn mb-3"
                                                    onClick={Handlenavigate}
                                                >
                                                    Send Invitation
                                                </button>
                                            </div>
                                        )}
                                        <div className="submit_banner mt-5">
                                            {projectsBidding.length > 0 && (
                                                <h3 className="sub_head">Submitted Proposals</h3>
                                            )}
                                            {!loading && projectsBidding.length == 0 && (
                                                <h3 className="sub_head text-center">
                                                    No Proposals Submitted So Far!
                                                </h3>
                                            )}
                                            <ul className="proposal_list">
                                                {projectsBidding.map((item, index) => (
                                                    <li>
                                                        <div className="propocal_card dropdown">
                                                            <div className="color_bg">
                                                                <div className="project_head">
                                                                    <h2>{item?.user?.fname}</h2>{" "}
                                                                    <span>
                                                                        Submitted{" "}
                                                                        {moment(item?.created_at).format(
                                                                            "MMMM D, YYYY"
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="proposal_content">
                                                                            <p>
                                                                                <strong>Comments: </strong>{" "}
                                                                                {item?.description
                                                                                    ? item?.description
                                                                                    : "N/A"}{" "}
                                                                            </p>
                                                                        </div>
                                                                        <div className="proposal_content">
                                                                            <p
                                                                                className="dropdown-toggle lastest-communication"
                                                                                onClick={() => showChat(index)}
                                                                            >
                                                                                <strong>Latest Communication </strong>
                                                                            </p>
                                                                            <p>
                                                                                {item?.latest_chat?.message
                                                                                    ? item?.latest_chat?.message.length >
                                                                                        200
                                                                                        ? `${item?.latest_chat?.message.slice(
                                                                                            0,
                                                                                            200
                                                                                        )}...`
                                                                                        : item?.latest_chat?.message
                                                                                    : "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="proposal_content proposal_detail">
                                                                            <p className="mb-2">
                                                                                <strong>Proposal Documents</strong>
                                                                            </p>
                                                                            <ul className="proposal_files">
                                                                                {item?.files?.length > 0 ? (
                                                                                    item?.files?.map((file, index) => (
                                                                                        <li key={index}>
                                                                                            <a
                                                                                                className="text"
                                                                                                href={file?.plan_doc}
                                                                                                target="_blank"
                                                                                            >
                                                                                                <span>
                                                                                                    <img
                                                                                                        src="/assets/images/file.png"
                                                                                                        alt=""
                                                                                                        className="mb-1 me-2"
                                                                                                    />
                                                                                                    {getFileNameFromURL(
                                                                                                        file?.plan_doc
                                                                                                    )}
                                                                                                </span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))
                                                                                ) : (
                                                                                    <>
                                                                                        <p>N/A</p>
                                                                                    </>
                                                                                )}
                                                                            </ul>

                                                                            <p className="mb-2">
                                                                                <strong>Additional Documents</strong>
                                                                            </p>
                                                                            <ul className="proposal_files">
                                                                                {item?.additionalfiles?.length > 0 ? (
                                                                                    item?.additionalfiles?.map((file, index) => (
                                                                                        <li key={index}>
                                                                                            <a
                                                                                                className="text"
                                                                                                href={file?.additional_doc}
                                                                                                target="_blank"
                                                                                            >
                                                                                                <span>
                                                                                                    <img
                                                                                                        src="/assets/images/file.png"
                                                                                                        alt=""
                                                                                                        className="mb-1 me-2"
                                                                                                    />
                                                                                                    {getFileNameFromURL(
                                                                                                        file?.additional_doc
                                                                                                    )}
                                                                                                </span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))
                                                                                ) : (
                                                                                    <>
                                                                                        <p>N/A</p>
                                                                                    </>
                                                                                )}
                                                                            </ul>

                                                                            <div className="award_review">
                                                                                <div className="award_review">
                                                                                    <Ratting bidData={item} />
                                                                                </div>
                                                                                <div className="notes w-50">
                                                                                    <div className="proposal_content">
                                                                                        <p
                                                                                            className="mb-1 dropdown-toggle"
                                                                                            onClick={() => showNotes(index)}
                                                                                        >
                                                                                            <strong>Notes </strong>
                                                                                        </p>
                                                                                        <p className="">
                                                                                            {item?.latest_notes?.notes
                                                                                                ? item?.latest_notes?.notes
                                                                                                : "N/A"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {item.status == "hired" ? (
                                                                                <div className="create_btn">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleAward(item, index)
                                                                                        }
                                                                                        className="awarded_btn"
                                                                                        disabled={true}
                                                                                    >
                                                                                        {loadingIndex === index ? (
                                                                                            <Loader />
                                                                                        ) : (
                                                                                            "Awarded"
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            ) : item.status == "pending" ? (
                                                                                <div className="create_btn">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleAward(item, index)
                                                                                        }
                                                                                        className="globle_btn"
                                                                                        disabled={loadingIndex === index}
                                                                                    >
                                                                                        {loadingIndex === index ? (
                                                                                            <Loader />
                                                                                        ) : (
                                                                                            "Award Project"
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="create_btn">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleAward(item, index)
                                                                                        }
                                                                                        className="rejected_btn"
                                                                                        disabled={true}
                                                                                    >
                                                                                        {loadingIndex === index ? (
                                                                                            <Loader />
                                                                                        ) : (
                                                                                            "Rejected"
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {openChatIndex == index && (
                                                                <Chat
                                                                    setOpenChatIndex={setOpenChatIndex}
                                                                    bidData={item}
                                                                    Proposal Documents onChatClose={handleRefresh}
                                                                />
                                                            )}
                                                            {openNotesIndex === index && (
                                                                <Notes
                                                                    setOpenNotesIndex={setOpenNotesIndex}
                                                                    bidData={item}
                                                                    updateFlag={updateFlag}
                                                                />
                                                            )}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}

                                {/* ISPENDING CODE BLOCK */}

                                {isPending && (
                                    <div className="submit_banner mt-5">
                                        {projectsBidding.filter(item => item.user_id === userId).length > 0 && (
                                            <h3 className="sub_head">Submitted Proposals</h3>
                                        )}
                                        {!loading && projectsBidding.filter(item => item.user_id === userId).length == 0 && (
                                            <h3 className="sub_head text-center">
                                                No Proposals Submitted So Far!
                                            </h3>
                                        )}
                                        <ul className="proposal_list">
                                            {projectsBidding.filter(item => item.user_id === userId).map((item, index) => (
                                                <li key={index}>
                                                    <div className="propocal_card dropdown">
                                                        <div className="color_bg">
                                                            <div className="project_head">
                                                                <h2>{item?.user?.fname}</h2>{" "}
                                                                <span>
                                                                    Submitted{" "}
                                                                    {moment(item?.created_at).format("MMMM D, YYYY")}
                                                                </span>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="proposal_content">
                                                                        <p>
                                                                            <strong>Comments: </strong>{" "}
                                                                            {item?.description ? item?.description : "N/A"}{" "}
                                                                        </p>
                                                                    </div>
                                                                    <div className="proposal_content">
                                                                        <p
                                                                            className="dropdown-toggle lastest-communication"
                                                                            onClick={() => showChat(index)}
                                                                        >
                                                                            <strong>Latest Communication </strong>
                                                                        </p>
                                                                        <p>
                                                                            {item?.latest_chat?.message
                                                                                ? item?.latest_chat?.message.length > 200
                                                                                    ? `${item?.latest_chat?.message.slice(0, 200)}...`
                                                                                    : item?.latest_chat?.message
                                                                                : "N/A"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="proposal_content proposal_detail">
                                                                        <p className="mb-2">
                                                                            <strong>Proposal Documents</strong>
                                                                        </p>
                                                                        <ul className="proposal_files">
                                                                            {item?.files?.length > 0 ? (
                                                                                item?.files?.map((file, index) => (
                                                                                    <li key={index}>
                                                                                        <a
                                                                                            className="text"
                                                                                            href={file?.plan_doc}
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span>
                                                                                                <img
                                                                                                    src="/assets/images/file.png"
                                                                                                    alt=""
                                                                                                    className="mb-1 me-2"
                                                                                                />
                                                                                                {getFileNameFromURL(file?.plan_doc)}
                                                                                            </span>
                                                                                        </a>
                                                                                    </li>
                                                                                ))
                                                                            ) : (
                                                                                <p>N/A</p>
                                                                            )}
                                                                        </ul>

                                                                        <p className="mb-2">
                                                                            <strong>Additional Documents</strong>
                                                                        </p>
                                                                        <ul className="proposal_files">
                                                                            {item?.additionalfiles?.length > 0 ? (
                                                                                item?.additionalfiles?.map((file, index) => (
                                                                                    <li key={index}>
                                                                                        <a
                                                                                            className="text"
                                                                                            href={file?.additional_doc}
                                                                                            target="_blank"
                                                                                        >
                                                                                            <span>
                                                                                                <img
                                                                                                    src="/assets/images/file.png"
                                                                                                    alt=""
                                                                                                    className="mb-1 me-2"
                                                                                                />
                                                                                                {getFileNameFromURL(
                                                                                                    file?.additional_doc
                                                                                                )}
                                                                                            </span>
                                                                                        </a>
                                                                                    </li>
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    <p>N/A</p>
                                                                                </>
                                                                            )}
                                                                        </ul>
                                                                        <div className="award_review">
                                                                            <div className="notes w-50">
                                                                                <div className="proposal_content">
                                                                                    <p
                                                                                        className="mb-1 dropdown-toggle"
                                                                                        onClick={() => showNotes(index)}
                                                                                    >
                                                                                        <strong>Notes </strong>
                                                                                    </p>
                                                                                    <p className="">
                                                                                        {item?.latest_notes?.notes
                                                                                            ? item?.latest_notes?.notes
                                                                                            : "N/A"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {openChatIndex == index && (
                                                            <Chat
                                                                setOpenChatIndex={setOpenChatIndex}
                                                                bidData={item}
                                                                onChatClose={handleRefresh}
                                                            />
                                                        )}
                                                        {openNotesIndex === index && (
                                                            <Notes
                                                                setOpenNotesIndex={setOpenNotesIndex}
                                                                bidData={item}
                                                                updateFlag={updateFlag}
                                                            />
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DefaultLayout>
    );
}
