import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ColorRing, ThreeDots } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { toast } from 'react-toastify';


export default function ActiveProjects() {

    let navigate = useNavigate()
    const [projects, setprojects] = useState([]),
        [count, setCount] = useState(0),
        [pageCount, setPageCount] = useState(0),
        [loading, setLoading] = useState(true),
        [error, setError] = useState(false);
    const [jobStatus, setJobStatus] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [projectId, setProjectId] = useState(false);

    const fetchData = async () => {
        setLoading(true); // Start loading

        let url = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem('authToken');
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(
                url + `/sub_cont_active_project?page_num_start=1&page_size=20`,
                requestOptions
            );

            if (response.ok) {
                const data = await response.json();

                console.log(data)
                if (data?.data?.projects.length > 0) {
                    setCount(data.data.projectsCount / 10);
                    setPageCount(data.data.projectsCount);
                    setprojects(data.data.projects);
                } else {
                    setError(true); // Set an error state if no data is available
                }
            } else {
                setError(true); // Set an error state if the response is not ok
            }
            setLoading(false); // Stop loading
        } catch (error) {
            setError(true); // Set an error state if an error occurs
            setLoading(false); // Stop loading
        }
    };
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            fetchData();

        }, 1000);
    }, [jobStatus])

    const fetchPaginatedData = async (currentPage) => {
        let url = process.env.REACT_APP_BASE_URL;
        setLoading(true)
        const token = localStorage.getItem('authToken');
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const res = await fetch(
            url +
            `/sub_cont_active_project?page_num_start=${currentPage}&page_size=20`,
            requestOptions
        );
        const data = await res.json();
        setLoading(false)
        return data;
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        const dataFromServer = await fetchPaginatedData(currentPage);
        setprojects(dataFromServer?.data?.projects);
    };

    const handleChange = async (event, project) => {
        setJobStatus(event.target.value);
        setProjectId(project.id)

        if (event.target.value !== 100) {
            try {
                let url = process.env.REACT_APP_BASE_URL;
                const token = localStorage.getItem('authToken');

                const requestData = {
                    job_status: event.target.value,
                };

                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestData),
                };

                const res = await fetch(
                    url + `/update_project_status/${project.id}`,
                    requestOptions
                );

                const body = await res.json();

                if (body.success === true) {
                    toast.success('Job status added successfully!', { autoClose: 3000 });
                    setTimeout(() => {
                        setJobStatus(event.target.value);
                    }, 2000);

                } else {
                    toast.error('Something went wrong', { autoClose: 3000 });
                }

                console.log({ body });
            } catch (err) {
                console.error(err);
            }
        } else {
            setModalOpen(true)
        }
    };

    const handleSubmit = async () => {
        try {
            let url = process.env.REACT_APP_BASE_URL;
            const token = localStorage.getItem('authToken');

            const requestData = {
                job_status: event.target.value,
            };

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            };

            const res = await fetch(
                url + `/project_complete_status/${projectId}`,
                requestOptions
            );

            const body = await res.json();

            if (body.success === true) {
                toast.success('Job Completed successfully!', { autoClose: 3000 });

            } else {
                toast.error('Something went wrong', { autoClose: 3000 });
            }
            setModalOpen(false);
            setJobStatus('')
            console.log({ body });
        } catch (err) {
            console.error(err);
        }
    }

    const handleClose = () => {
        setJobStatus('')
        setModalOpen(false);
    }

    console.log(jobStatus)

    console.log(projects)

    return (
        < div>
            <div className='container'>
                <div className="about_projects">
                    <div className="color_bg pb-3">

                        {loading ? <div className="text-center loader_style">
                            <ThreeDots
                                height="100"
                                width="120"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                visible={true}
                            />
                        </div> : (
                            (projects.length == 0) ?
                                <div>
                                    <div className="text-center">
                                        <h3>No projects to show.</h3>
                                    </div>
                                </div> : (
                                    <ul className="project_boxes">
                                        {projects.map((item, index) => (
                                            <li>
                                                <div className="project_detail">
                                                    <div className="project_head cursor-pointer">
                                                        <h2 onClick={() => { navigate(`/project-details/${item.id}/true`) }}>{item.project_name} {item.award_bid !== null && <span>Awarded on {moment(item.award_bid.awarded_date).format("MMM DD, YYYY")}</span>}</h2>
                                                        <ul className="project_status">
                                                            {item?.award_bid !== null && <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Job Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id={`job-status-${index}`}
                                                                    value={item.status === 'completed' ? 100 : item.job_status || []}
                                                                    onChange={(e) => { handleChange(e, item) }}
                                                                    disabled={item.status === 'completed'}
                                                                    label="Job Status"
                                                                >
                                                                    <MenuItem value={0}>0%</MenuItem>
                                                                    <MenuItem value={10}>10%</MenuItem>
                                                                    <MenuItem value={20}>20%</MenuItem>
                                                                    <MenuItem value={30}>30%</MenuItem>
                                                                    <MenuItem value={40}>40%</MenuItem>
                                                                    <MenuItem value={50}>50%</MenuItem>
                                                                    <MenuItem value={60}>60%</MenuItem>
                                                                    <MenuItem value={70}>70%</MenuItem>
                                                                    <MenuItem value={80}>80%</MenuItem>
                                                                    <MenuItem value={90}>90%</MenuItem>
                                                                    <MenuItem value={100}>100%</MenuItem>
                                                                </Select>
                                                            </FormControl>}

                                                        </ul>
                                                    </div>
                                                    <p><strong>Project Description:</strong>{item.project_description}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )
                        )}

                        {pageCount > 10 && <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={count}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"item-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"item-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"item-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"item-link"}
                            activeClassName={"active"}
                        />}
                    </div>
                    <div className="creat_btn text-center my-5">
                        <a className="me-4" href="/find-a-project">Find a Project</a>
                        {/* <a href="/post-new-project">Post a Project</a> */}
                    </div>
                </div>
            </div>
            {modalOpen &&
                <Dialog open={modalOpen} onClose={handleClose}>
                    <DialogTitle className='delete-modal-header'>Complete Confirmation</DialogTitle>
                    <p className='my-2 p-3'>
                        Do you want to mark this project as complete?
                    </p>
                    <div className='d-flex  justify-content-between  mb-3'>
                        <button onClick={handleClose} className='cancel_confirm_btn ms-3'>
                            Cancel
                        </button>
                        <button onClick={handleSubmit} className='submit_confirm_btn me-3'>
                            Confirm
                        </button>
                    </div>
                </Dialog>
            }
        </div>
    )
}
