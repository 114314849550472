import React from 'react'
import DefaultLayout from '../reusableComponents/defaultLayout'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const emailPatternValidator = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export default function ForgetPassword() {

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .required('Email is required')
            .matches(emailPatternValidator, 'Invalid email address')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true); // Set submitting state when form submission begins
            const baseOrigin = window.location.origin;
            const requestData = {
                email: values.email,
                baseurl: baseOrigin + '/',
            };
            let url = process.env.REACT_APP_BASE_URL;

            const response = await fetch(`${url}/forgot_password_email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const { data, message, success, error } = await response.json();
                if (success) {
                    toast.success('Email sent successfully!', { autoClose: 3000 });
                } else {
                    toast.error('Failed', data.error, { autoClose: 3000 });
                }
            } else {
                const { data, message, success, error } = await response.json();
                console.log(error)
                toast.error(error, { autoClose: 3000 });
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setSubmitting(false); // Ensure submitting state is set to false even if an error occurs
        }
    };
    return (
        <>
            <DefaultLayout>
                <section className="title_head">
                    <div className="sign_up">
                        <h1>Forget Password?</h1>
                    </div>
                </section>
                <section className="form_style">
                    <div className="container">
                        <div className="color_bg">
                            <div className="sign_up">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, touched, errors }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <label
                                                    className="form-label"
                                                >
                                                    Email Address
                                                </label>
                                                <Field
                                                    name="email"

                                                    type="email"
                                                    placeholder="Enter Email Address"
                                                    className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                                                        }`}
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <div >
                                                <button
                                                    type="submit"
                                                    className="submit_btn"
                                                    disabled={isSubmitting}
                                                >
                                                    Retrieve Password {isSubmitting && (
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </DefaultLayout>


        </>
    )
}
